.bubbleBox{
    position: relative;
    height:300px;
    width: 80px;
}

.bubbles{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}
